import GuardsButtons from "@components/atoms/GuardsButtons";
import Guard from "@components/atoms/Guard";
import { useState } from "react"
import { useIsMobile } from "@hooks/useIsMobile";
import GuardsDropdown from "@components/atoms/GuardsDropdown";
import useTranslation from "next-translate/useTranslation";

export default function Guards () {
  const isMobile = useIsMobile();
  const { t, lang } = useTranslation('common');
  const [showAnimation, setShowAnimation] = useState(false);

  const guards = [
    {
      position: t('guards.guards.vanguard.position'),
      name: t('guards.guards.vanguard.name'),
      description: t('guards.guards.vanguard.description'),
      image: "/guards/Ra-icon.webp"
    },
    {
      position: t('guards.guards.admiral.position'),
      name: t('guards.guards.admiral.name'),
      description: t('guards.guards.admiral.description'),
      image: "/guards/Anu2-icon.webp"
    },
    {
      position: t('guards.guards.captain.position'),
      name: t('guards.guards.captain.name'),
      description: t('guards.guards.captain.description'),
      image: "/guards/Ob-new.webp"
    },
    {
      position: t('guards.guards.titan.position'),
      name: t('guards.guards.titan.name'),
      description: t('guards.guards.titan.description'),
      image: "/guards/Icar-icon.webp"
    },
    {
      position: t('guards.guards.commander.position'),
      name: t('guards.guards.commander.name'),
      description: t('guards.guards.commander.description'),
      image: "/guards/Azazel-icon.webp"
    },
    {
      position: t('guards.guards.code.position'),
      name: t('guards.guards.code.name'),
      description: t('guards.guards.code.description'),
      image: "/guards/Horus-icon.webp"
    }
  ];

  const [active, setActive] = useState(0);
  const [showMore, setShowMore] = useState(false)

  return (
    <div className='flex flex-col items-center relative'>
      {
        isMobile 
          ? <div className='h-[72px] w-full'><GuardsDropdown guards={guards} active={active} setActive={setActive} setBlur={setShowMore} /></div>
          : <GuardsButtons guards={guards} active={active} setActive={setActive} showAnimation={showAnimation} setShowAnimation={setShowAnimation} />
      }
      <div className={`${showMore ? 'blur-sm' : 'blur-none'} ${showAnimation ? 'translate-to-top' : 'translate-to-bottom'}`}>
        <Guard guard={guards[active]} />
      </div>
    </div>
  )
}
