import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Guards from '@components/molecules/Guards'
import useTranslation from 'next-translate/useTranslation'

export default function NestGuards ({ setDiscoverMenuItem } : any) {
  const { ref, inView } = useInView({threshold: 0.1})
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem('guards')
    }
  }, [inView])

  return (
    <section 
      id='guards' 
      ref={ref}
      className={`section ${!inView && 'opacity-0'}`}
    >
      <div className='w-full py-[60px] md:py-[80px]  px-4'>
        <div className={`${inView && 'section-title'} mb-20 ml-[25%]`}>
          <h6>/ {t('guards.title')}</h6>
        </div>
        <div 
          className={`flex justify-center ${!inView && 'opacity-0'} ${inView && 'opacity-animation-long'}`}
          style={{
            transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
            animationDuration: '1s'
          }}
        >
          <Guards />
        </div>
      </div>
    </section>
  )
}
