import { useEffect, useRef, useState } from 'react';
import { Guard } from 'types/Guard.type';
import styles from './styles.module.css';
import { useIsTablet } from '@hooks/useIsTablet';

export default function GuardsButtons ({guards, active, setActive, showAnimation, setShowAnimation }: {guards: Guard[], active: number, setActive: (index: number) => void, showAnimation: boolean, setShowAnimation: (value: boolean) => void}) {
  const [showMore, setShowMore] = useState(true);
  const isTablet = useIsTablet()
  const [buttonsNumber, setButtonsNumber] = useState(4);

  useEffect(() => {
    isTablet && setButtonsNumber(3)
  }, [isTablet])

  const handleShow = () => {
    setShowMore(!showMore)
    setShowAnimation(!showAnimation)
  }

  return (
    <div className='flex flex-col items-center'>
      <div className={`flex flex-wrap justify-start overflow-hidden ${styles.container}}`}>
        {
          guards.map((guard: Guard, index: number) => {
            return (
              <div key={index}>
                {
                  index < buttonsNumber &&
                  <button
                    onClick={() => setActive(index)}
                    name='guard position'
                    className={`
                      font-[BeVietnam] text-[16px] font-light leading-[24px] py-2 px-8
                      border-solid border-[1px] rounded-tl-[8px] rounded-br-[8px] mr-6 mb-4
                      hover:border-primary
                      ${active === index ? 'text-[#111111] border-primary bg-primary' : 'text-white border-white bg-transparent hover:text-primary'}
                    `}
                  >
                    {guard.position}
                  </button>
                }
              </div>
            )
          })
        }
      </div>
      {
        !showMore &&
        <div className={`flex flex-wrap justify-start overflow-hidden ${styles.container} ${styles['show-container']}`}>
        {
          guards.map((guard: Guard, index: number) => {
            return (
              <div key={index}>
                {
                  index >= buttonsNumber &&
                  <button
                    onClick={() => setActive(index)}
                    name='guard position'
                    className={`
                      font-[BeVietnam] text-[16px] font-light leading-[24px] py-2 px-8
                      border-solid border-[1px] rounded-tl-[8px] rounded-br-[8px] mr-6 mb-4
                      hover:border-primary
                      ${active === index ? 'text-[#111111] border-primary bg-primary' : 'text-white border-white bg-transparent hover:text-primary'}
                    `}
                  >
                    {guard.position}
                  </button>
                }
              </div>
            )
          })
        }
      </div>
      }
      <button
        onClick={() => handleShow()}
        name='show more'
        className={`flex flex-col items-center cursor-pointer mt-8 ${showMore && styles['translate-to-top']}`}
      >
        <p className='text-[#B0B0B0] font-light mb-1'>Show {showMore ? 'More' : 'Less'}</p>
      </button>
    </div>
  )
}

