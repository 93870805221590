import { Guard } from 'types/Guard.type';
import Image from 'next/image';
import styles from './styles.module.css';
import { useEffect, useState } from 'react';

export default function Guard ({guard}: {guard: Guard}) {
  const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [oldGuard, setOldGuard] = useState(guard)

  useEffect(() => {
    setTriggerAnimation(true)
    const timeout = setTimeout(() => {
      setOldGuard(guard)
    }, 250)

    const timeoutId = setTimeout(() => {
      setTriggerAnimation(false)
    }, 500)
    return () => {clearTimeout(timeoutId); clearTimeout(timeout)}
  }, [guard.position])

  return (
    <div className={`grid gap-8 w-full mt-[48px] ${styles.container} ${triggerAnimation && styles['opacity-animation']}`}>
      <div className={`relative ${styles.image} overflow-hidden rounded-br-[24px] rounded-tl-[24px]`}>
        <Image loading='lazy' width={410} height={502} src={oldGuard.image} alt={`${oldGuard.position} representation`} style={{ height: 'auto', width: '100%'}} />
      </div>
      <div className='flex flex-col items-start justify-center'>
        <h1 className='xs:font-semibold md:font-bold text-primary mb-2 font-[ThunderBold]'>{oldGuard.name}</h1>
        <div className='w-full h-[0.5px] bg-[#B0B0B0]'></div>
        <div className='mt-8 mb-4'>
          <span className='font-light font-[BeVietnam] text-[10px] leading-[16px] text-white'>Position:</span>
          <p className='font-[BeVietnam] text-[24px] font-semibold leading-[40px] text-white mt-4'>{oldGuard.position}</p>
        </div>
        <div className='w-full h-[0.5px] bg-[#B0B0B0]'></div>
        <div className='mt-8 mb-4'>
          <span className='font-light font-[BeVietnam] text-[10px] leading-[16px] text-white'>Description:</span>
          <p className='font-[BeVietnam] text-[16px] leading-[28px] text-white mt-4'>{oldGuard.description}</p>
        </div>
        <div className='w-full h-[0.5px] bg-[#B0B0B0]'></div>
      </div>
    </div>
  )
}
