import { useEffect, useRef, useState } from 'react';
import { Guard } from 'types/Guard.type';
import styles from './styles.module.css';

export default function GuardsDropdown ({guards, active, setActive, setBlur }: {guards: Guard[], active: number, setActive: (index: number) => void, setBlur: any}) {
  const [showMore, setShowMore] = useState(false);
  const containerRef = useRef<any>(null);
  const [itemsToDisplay, setItemsToDisplay] = useState(guards);

  const handleShow = () => {
    if(!containerRef.current)
      return

    if(!showMore) {
      containerRef.current.classList.remove(styles['condense-container'])
      containerRef.current.classList.add(styles['expand-container'])
      setBlur(true)
    } else {
      containerRef.current.classList.remove('hidden')
      containerRef.current.classList.remove(styles['expand-container'])
      containerRef.current.classList.add(styles['condense-container'])
      setBlur(false)
      setTimeout(() => {
        containerRef.current.classList.add('hidden')
      }, 500)
    }
    setShowMore(!showMore)
  }

  useEffect(() => {
    const temp = guards.filter(el => el.position !== guards[active].position)
    setItemsToDisplay(temp)
  }, [active])

  const handleChooseItem = (position: string) => {
    const tempItemToBeFound: any = itemsToDisplay.filter(el => el.position === position)
    const tempIndex = guards.findIndex(el => el.position === tempItemToBeFound[0].position)
    setActive(tempIndex)

    containerRef.current.classList.remove('hidden')
    containerRef.current.classList.remove(styles['expand-container'])
    containerRef.current.classList.add(styles['condense-container'])
    setTimeout(() => {
      containerRef.current.classList.add('hidden')
    }, 500)
    setShowMore(false)
    setBlur(false)
  }

  return (
    <div className='absolute w-full' style={{ zIndex: 2 }}>
      <div 
        className={`
          font-[BeVietnam] text-[16px] font-light leading-[24px] cursor-pointer overflow-hidden bg-[#111]
          border-solid border-[1px] border-white rounded-tl-[8px] rounded-br-[8px] mb-4 text-primary w-full
        `}
      >
          <div onClick={() => handleShow()} className='flex justify-between items-center px-8 py-6 bg-[#111]'>
            <span>{guards[active].position}</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={showMore ? 'rotate-180' : 'rotate-0'}>
              <path d="M4 6L8 10L12 6" stroke={showMore ? "#ffd10a" : "white"} strokeLinecap="round"/>
            </svg>
          </div>
          <div ref={containerRef} className='hidden'>
            {
              itemsToDisplay.map((guard: Guard, index: number) => {
                return (
                  <div key={index}>
                    <button
                      onClick={() => handleChooseItem(guard.position)}
                      name='guard position'
                      className={`
                        font-[BeVietnam] text-[16px] font-light leading-[24px] py-6 px-8
                        border-solid border-t-[1px] border-white w-full
                        text-white text-left
                      `}
                    >
                      {guard.position}
                    </button>
                  </div>
                )
              })
            }
          </div>
      </div>
    </div>
  )
}

